export default {
    listEnum: [
        {
            text: 'Pendente',
            value: 1,
        },
        {
            text: 'Cancelado',
            value: 2,
        },
        {
            text: 'Recusado',
            value: 3,
        },
    ]
  }
    