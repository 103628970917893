import Vue from 'vue'

const CancellationRequestsService = {

  getAll(filters) {
    return Vue.prototype.$http.get(`/api/services/app/CancellationRequestses/GetAll?=`+filters)
  },

  createOrEdit(request) {
    return Vue.prototype.$http.post(`/api/services/app/CancellationRequestses/CreateOrEdit`, request)
  },
  
  existsRequest(investmentId) {
    return Vue.prototype.$http.get(`/api/services/app/CancellationRequestses/GetExistsRequest?investmentQuotaId=`+investmentId)
  },

  getCancellationRequestsForEdit(id) {
    return Vue.prototype.$http.get(`/api/services/app/CancellationRequestses/GetCancellationRequestsForEdit?Id=`+id)
  },
}

export default CancellationRequestsService
