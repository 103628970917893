<template>
  <div>
    <div>
      <b-button v-b-toggle.collapse-1 variant="primary">Filtros avançados</b-button>
      <b-collapse id="collapse-1" class="mt-2">
        <b-card>
          <b-row>
            <b-col cols="3">
                <b-form-group label="Imóvel" label-for="immobile">
                    <b-form-input v-model="filters.immobileNameFilter" />
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Solicitação Inicio" label-for="payment-type">
                  <b-input-group>
                    <b-form-input
                      id="acquisitionStartFilter"
                      v-model="filters.minDateFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                        <b-input-group-append>
                          <b-input-group-text>
                            <b-link @click="filters.minDateFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                          </b-input-group-text>
                        </b-input-group-append>
                  </b-input-group>                    
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Solicitação Fim" label-for="payment-type">
                   <b-input-group>
                    <b-form-input
                      id="acquisitionFinishFilter"
                      v-model="filters.maxDateFilter"
                      v-mask="'##/##/####'"
                      placeholder=""/>
                      <b-input-group-append>
                        <b-input-group-text>                          
                            <b-link @click="filters.maxDateFilter = undefined">
                              <b-icon icon="x" />
                            </b-link>
                        </b-input-group-text>
                      </b-input-group-append>
                   </b-input-group>
                </b-form-group>
            </b-col>           
            <b-col cols="2">
                <b-form-group label="Status Cancelamento" label-for="status-cota">
                    <b-form-select
                        id="status-cota"
                        v-model="filters.statusFilter"
                        label="Status da Cota"
                        :options="listStatus"/>
                </b-form-group>
            </b-col> 
            </b-row>
            <b-row>
              <div class="aling-buttons button-left" cols="1">
                <b-button variant="primary" @click="clear()">Limpar</b-button>  
              </div>
              <div class="aling-buttons" cols="1">
                <b-button variant="primary" @click="getInvestments()">Pesquisar</b-button>  
              </div>
            </b-row>
        </b-card>
      </b-collapse>
    </div>
    <vue-good-table
      id="table_cancell"
      :columns="columns"
      :rows="investments"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: search,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      @on-sort-change="sortChange">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'userInvestorName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.userInvestorName }}</span>
        </span>

        <span v-else-if="props.column.field === 'immobileName'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.immobileName }}</span>
        </span>

        <span v-else-if="props.column.field === 'date'" class="text-nowrap">
          <span class="text-nowrap">{{ formatToDate(props.row.date) }}</span>
        </span>

        <span v-else-if="props.column.field === 'dateStatus'" class="text-nowrap">
          <span class="text-nowrap">{{ formatToDate(props.row.dateStatus) }}</span>
        </span>

        <span v-else-if="props.column.field === 'status'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.status }}</span>
        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-link v-b-tooltip.hover.v-secondary title="Editar" :to="{ name: 'manager-cancell-investment', params: { id: props.row.id, path: `/manager-cancell-investment/${props.row.id}` }, }">
            <feather-icon icon="Edit2Icon" class="mr-50" />
          </b-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span class="text-nowrap"> de {{ totalCount }} Resultados </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {  BInputGroupText, BInputGroupAppend, BPagination, BFormSelect, BLink, VBTooltip, BBadge, BIcon, BCard, BCollapse, VBToggle, BButton, BRow, BCol, BFormGroup, BFormInput, BInputGroup} from 'bootstrap-vue'
import BackgroundCard from '../../template/BackgroundCard.vue'
import CancellationRequestsService from '@/services/Investment/CancellationRequestsService'
import { VueGoodTable } from 'vue-good-table'
import { codeBasic } from '../../codeTable'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import formatter from '@/mixins/formatter'
import SearchBarVue from '@/layouts/navbar/components/SearchBar.vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import CancelationEnum from '@/enums/CancelationEnum'

export default {
  components: {
    BInputGroupText, 
    BInputGroupAppend,
    BInputGroup,
    BPagination,
    BackgroundCard,
    BFormSelect,
    BCard,
    BCollapse,
    BButton,
    BLink,
    BBadge,
    VueGoodTable,
    BIcon,
    SearchBarVue, 
    BFormGroup, 
    BRow, 
    BCol,
    BFormInput
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  
  mixins:[formatter],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,    
    'b-toggle': VBToggle
  },
  data: () => ({
    investments: [],
    sorting: '',
    pageLength: 10,
    currentPage: 1,
    dir: false,
    codeBasic,
    totalCount: 0,
    filters: {},
    search: '',
    columns: [
      {
        label: 'Investidor',
        field: 'userInvestorName',
      },
      {
        label: 'Imóvel',
        field: 'immobileName',
      },
      {
        label: 'Data da Solicitação',
        field: 'date',
      },
      {
        label: 'Data da Aprovação',
        field: 'dateStatus',
      },
      {
        label: 'Status Cancelamento',
        field: 'status',
      },
      {
        label: '',
        field: 'action',
      },
    ],
    config: {
      dateFormat: 'd/m/Y',
      locale: Portuguese,
    },
    listStatus: CancelationEnum.listEnum,
  }),
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },

  watch: {
    currentPage: {
      handler(val) {
        this.getInvestments()
      },
      immediate: true,
      }
  },

  methods: {
    async getInvestments(){
      let filters = this.getFilters()
      filters = `${filters}&SkipCount=${(10 * this.currentPage) - 10}&Sorting=${this.sorting}`
      const result = (await CancellationRequestsService.getAll(filters)).data.result
      this.investments = result.items
      this.investments.forEach(i => i.status = this.statusVariantName(i.status))
      this.totalCount = result.totalCount
    },
    sortChange(event) {
      this.sorting =  event[0].field + ' ' + event[0].type
      this.getInvestments()
    },

    getFilters(){
      let filters = ``
      if(this.filters.immobileNameFilter !== undefined) {
        filters = `${filters}&immobileNameFilter=${this.filters.immobileNameFilter}`
      }
      if(this.filters.minDateFilter !== undefined) {
        filters = `${filters}&minDateFilter=${formatter.formatToDate(this.filters.minDateFilter)}`
      }
      if(this.filters.maxDateFilter !== undefined) {
        filters = `${filters}&maxDateFilter=${formatter.formatToDate(this.filters.maxDateFilter)}`
      }
      if(this.filters.statusFilter !== undefined) {
        filters = `${filters}&statusFilter=${this.filters.statusFilter}`
      }
      return filters
    },

    clear(){
      this.filters = {}
      this.getInvestments()
    },

    statusVariantName(status) {
      switch(status) {
        case 1:
          return 'Pendente'
        case 2:
          return 'Cancelado'
        case 3:
          return 'Recusado'
      }
    },

    formatToDate(date){
      return date === undefined || date === null ? '' : formatter.formatDate(date)
    }

  },

  async mounted() {
    this.getInvestments()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.badge {
  width: 100% !important;
}
.text-center-position {
  margin-left: 45%;
}
body {
  background-color: #f8f8f8 !important;
}
#table_investments tr {
 
}


#table_cancell th:nth-child(1) { width: 25% !important;  }
#table_cancell th:nth-child(2) { width: 20% !important;  }
#table_cancell th:nth-child(3) { width: 20% !important;  }
#table_cancell th:nth-child(4) { width: 20% !important;  }
#table_cancell th:nth-child(5) { width: 15% !important;  }
#table_cancell th:nth-child(6) { width: 10% !important;  }

#table_cancell table tbody td:nth-child(1)  { width: 25% !important;  }
#table_cancell table tbody td:nth-child(2)  { width: 20% !important;  }
#table_cancell table tbody td:nth-child(3)  { width: 20% !important;  }
#table_cancell table tbody td:nth-child(4)  { width: 20% !important;  }
#table_cancell table tbody td:nth-child(5)  { width: 15% !important;  }
#table_cancell table tbody td:nth-child(6)  { width: 10% !important;  }

#table_cancell table.vgt-table {
  background-color: #f8f8f8 !important;
  border: #f8f8f8 !important;
}

#table_cancell a { color: #828282; }
#table_cancell a:hover { color: var(--color); }

.space-icon { margin-left: 10px; }


.aling-buttons{
  margin-top: 1.6%;
}
.button-left{
  margin-left: 1%!important;
  margin-right: 1% !important;
}
</style>
